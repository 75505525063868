<template>
    <main-container>
        <header-container>
            <view-particalur-hospital-header/>
        </header-container>
        <div style="height: 94vh; max-height: 94vh; padding-bottom: 100px; overflow-y: scroll; scroll-behavior: smooth;" id="scrollableDiv">
                
            <div id="searchMe" style="display: block;width: 100%; max-width: 425px; position: relative; top: 0; z-index: 1;">
                    <hospitalBanner/>
                    <select-speciality-and-search/>
                </div>
                <div id="AllDetails" class="allDetail" style="display: none;">
                    <div>All Details</div>
                    <img src="https://s3iconimages.mymedicine.com.mm/DropdownIcon.svg" @click="showSelectRegion"/>
                </div>
            <!-- </div> -->

            <div style="width: 100%; " >
                <div v-if="showDoctorCompLoader" style="display: flex; flex: 1;  align-items:center; justify-content: center;">
                    <img src="https://s3iconimages.mymedicine.com.mm/doctorSearchGif.gif" alt="" style="width: 250px; height: auto;">
                </div>

                <div v-else style="display: flex; flex-direction: column; gap: 16px; padding-top: 16px; margin-bottom: 50px;">
                    <div v-if="getDoctorList.length == 0" style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 4px; color: grey;  height: 60vh;">
                        <img src="https://s3iconimages.mymedicine.com.mm/noContentFound.svg" alt="">
                        No Doctors found
                    </div>
                    <div v-else >
                        <view-particular-doctor-comp v-for="(item, index) in getDoctorList" :key="item.doctorInfo.id" :dependent_members="dependent_members" :item="item"  style="margin-bottom: 10px;" @toggleSlot="toggleSlot" :isSelectedDoctorBooking="selectedDoctorBooking"/>
                    </div>
                </div>
            </div>
        </div>
    </main-container>
</template>

<script>
import axios from "axios";
import BodyContainer from '../../../templates/bodyContainer.vue';
import HeaderContainer from '../../../templates/headerContainerOPDBooking.vue';
import MainContainer from '../../../templates/mainContainer.vue';
import hospitalBanner from '../componentUsedInPage/viewHospitalPageComponents/hospitalBannerComp.vue';
import SelectSpecialityAndSearch from '../componentUsedInPage/viewHospitalPageComponents/selectSpecialityAndSearch.vue';
import ViewParticalurHospitalHeader from '../componentUsedInPage/viewHospitalPageComponents/viewParticalurHospitalHeader.vue';
import ViewParticularDoctorComp from '../componentUsedInPage/viewHospitalPageComponents/viewParticularDoctorComp.vue';
import HospitalHeaderComp from '../componentUsedInPage/opdHomePage/hospitalHeaderComp.vue';
import SelectRegionAndSeachComp from '../componentUsedInPage/opdHomePage/selectRegionAndSeachComp.vue';
import SingleHospitalComp from '../componentUsedInPage/opdHomePage/singleHospitalComp.vue';
import { axios_auth_instance_customer, axios_auth_instance_hba_customer } from '../../../utils/axios_utils';
import HospitalSkeletonLoader from '../componentUsedInPage/opdHomePage/hospitalSkeletonLoader.vue';



export default {
  components: {BodyContainer, HeaderContainer, HospitalHeaderComp, SelectRegionAndSeachComp, SingleHospitalComp, ViewParticalurHospitalHeader, MainContainer, hospitalBanner, SelectSpecialityAndSearch, ViewParticularDoctorComp, HospitalSkeletonLoader },
    name: 'ViewParticularHospital',
    data: function() {
        return {
            token: null,
            todayAppointment: [],
            pageLoading: true,
            ongoingAppointment: [],
            bannerURL: '',
            scrollableDiv: null,
            searchMeDiv: null,
            allDetailsDiv: null,
            dependent_members: [],
            currentCustomer: null,
            doctorList: [],
            hospitalRegNumber: null,
            searchBoxDiv: null,
            selectedDoctorBooking: -1,
        };
    },
    computed: {
        showHospitalInfoLoader () {
            return this.$store.state.particularHospitalState.fetchingHospitalInfo;        
        },
        showDoctorCompLoader () {
            return this.$store.state.particularHospitalState.fetchingData;        
        },
        getDoctorList() {
            return this.$store.state.particularHospitalState.shownDoctorList || [];        
        }
    },
    async mounted() {
        if (!window.xm)
			this.token = this.$cookies.get('customerToken');
		else
			this.token = localStorage.getItem('customerToken');

        this.hospitalRegNumber = this.$route.params.hospitalRegNumber
        console.log(this.hospitalRegNumber);
        this.scrollableDiv = document.getElementById('scrollableDiv');
        this.searchBoxDiv = document.getElementById('searchBox');

        this.searchMeDiv = document.getElementById('searchMe');
        this.allDetailsDiv = document.getElementById('AllDetails');
        this.scrollableDiv.addEventListener('scroll', this.handler);
        this.getDependentData();
        this.$store.dispatch('loadHospitalInfo', {hospitalRegNumber: this.hospitalRegNumber});        
        this.$store.dispatch('loadDoctorList', {hospitalRegNumber: this.hospitalRegNumber});
        
        this.userPageViewTable();
    },

    methods: {
        userPageViewTable() {
            if (this.token === null) {
                var userPageViewTableBody = {
                    pageName : 'DoctorListingInHospital',
                    typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                    pageName: 'DoctorListingInHospital',
                    typeOfUser: 'CUSTOMER',
                    token: this.token
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
        },
        toggleSlot(item) {
            this.selectedDoctorBooking = item.id;
        },
        async getDependentData() {
            this.currentCustomer = this.$cookies.get("customerToken");
                let customerData = {
                token: this.currentCustomer,
                typeOfUser: "CUSTOMER",
            };
            try {
                let dependentResponse = await axios_auth_instance_hba_customer.get("/user/dependents");
                var dependent_list = [];
                var dependent_members = dependentResponse.data.dependentList;
                this.dependent_members = dependent_members;
            } catch (error) {
                console.log(error);
            } 
        },

        handler() {
            this.searchMeDiv.style.position = 'relative';
            this.searchMeDiv.style.top = 0;
            let rect = this.searchBoxDiv.getBoundingClientRect();
            let divTop = rect.top + window.scrollY;
            console.log(divTop)
            if(divTop < 10) {
            this.searchMeDiv.style.display = 'none';
            this.allDetailsDiv.style.display = 'flex'
            }
            // if (divTop <-100) {
            //     this.searchMeDiv.style.display = 'none';
            //     this.allDetailsDiv.style.display = 'flex'
            // } else {
            //     // Remove the class if the div is scrolled away from the top
            //     // myDiv.classList.remove('reachedTop');
            // }
            // console.log('Scrolling is happening inside the div!');

        },
        showSelectRegion() {
             this.scrollableDiv.removeEventListener('scroll', this.handler);
            this.searchMeDiv.style.display = 'block';
            this.searchMeDiv.style.position = 'sticky';

            this.allDetailsDiv.style.display = 'none'
            setTimeout(() => {
                this.scrollableDiv.addEventListener('scroll', this.handler);

            }, 1000)

        },
    },
};

</script>
<style  scoped>
.allDetail {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 12px 16px;
    background: white;
    border-bottom: 1px solid #ebebeb;
    position: sticky;
    max-width: 425px;
    top: 0;
    z-index: 10;
    /* display: none; */
}
</style>